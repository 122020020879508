@import "variables";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Scrollbar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Errors
.global-error {
  color: $error-color;
  border-bottom: 1px solid $error-color;
}

.button-error {
  background-color: $error-color !important;
  color: #ffffff !important;
}

.button-text-error {
  color: $error-color !important;
}

// ckeditor
.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by .ck-powered-by__label {
  display: none!important;
  border: 0;
  padding: 0;
  margin: 0;
}

.ck.ck-balloon-panel.ck-powered-by-balloon, .ck.ck-powered-by {
  display: none!important;
}

// Generic classes
.dflex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w100 {
  width: 100%;
}

.gutter-h {
  padding: 0 5px;
}

.gutter-v {
  padding: 5px 0;
}

.gutter {
  padding: 5px;
}

.mtop {
  margin-top: 25px;
}

.m10 {
  margin: 10px;
}

.mv-15 {
  margin: 15px auto !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.bold {
  font-weight: bold !important;
}

.inline-block {
  display: inline-block;
}

.pre-line {
  white-space: pre-line;
}

.jcontent-center {
  justify-content: center;
}

.jcontent-end {
  justify-content: flex-end;
}

.aitems-center {
  align-items: center;
}

.bg-primary {
  background-color: $main-color !important;
}

.bg-secondary {
  background-color: $second-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-error {
  background-color: $error-color !important;
}

.bg-blue-light {
  background-color: #eff5ff !important;
}

.color-success {
  color: $success-color;
}

.color-error {
  color: $error-color;
}

.color-secondary {
  color: $second-color;
}

.border-success {
  border-color: $success-color;
}

.border-error {
  border-color: $error-color;
}

.table-no-border-last {
  > tr {
    &:last-child {
      > td, > th {
        border-bottom: none;
      }
    }
  }
}

.MuiAvatar-root.small-avatar {
  width: 24px;
  height: 24px;
}

.MuiAvatar-root.medium-avatar {
  width: 32px;
  height: 32px;
}

.dotNumberSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-color;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  color: #ffffff;
}

.dotNumberSecondaryLight {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $second-color;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  color: #ffffff;
}

// Button like style
.buttonLike {
  border: 1px solid $second-color;
  border-radius: $basic-border-radius;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

// Paper
.btrip-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  padding: 40px 30px;
}

.btrip-paper-full {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 40px 30px;
  &.p15 {
    padding: 15px;
  }
}

.top-paper-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ccc;
  .top-paper-block-head {
    display: flex;
  }
  .top-paper-block-settings {
    background-color: $light-grey-color;
  }
}

.paper-header {
  background-color: $second-color;
  color: #ffffff;
  padding: 5px;
  border-top-left-radius: $basic-border-radius;
  border-top-right-radius: $basic-border-radius;
}

// Search bar
.searchBar {
  width: 300px;
  max-width: 500px;
  margin-left: 10px !important;
  margin-top: 10px !important;
}

// Flags
.flagSingleItem {
  display: flex !important;
  flex-direction: column !important;
  padding: 8px 15px !important;
}

// Tabs
.custom-tabs-container-vertical {
  display: flex;
  flex-grow: 1;
  .custom-tabs {
    border-right: 1px solid #ccc;
  }
}

// Forms
.customFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.customFormSuccess {
    .customField {
      > label {
        color: $success-color;
      }
      > .MuiInputBase-root {
        &.MuiInput-underline {
          &:before {
            border-bottom-color: $success-color;
          }
        }
      }
    }
  }
  .customForm {
    display: flex;
    flex-direction: column;
    .customField {
      margin-bottom: 12px;
    }
  }
}

.selectLittle {
  .MuiSelect-outlined {
    padding: 8.5px 14px;
  }
}

.customFormTitle {
  display: flex;
  justify-content: center;
  margin-bottom: 14px !important;
}

.buttonProgressContainer {
  position: relative;
  display: flex;
  .buttonProgress {
    color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
  .buttonContainedCenter {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }
}

.formTwoCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  > .MuiTextField-root {

  }
}

// Labels
.custom-tiny-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 14px;
  //font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.00938em;
}

// Steppers
.custom-active-step {
  .MuiStepIcon-root {
    &.MuiStepIcon-active {
      color: $second-color;
    }
  }
}

// Date range pickers
.DateRangePicker {
  .DateRangePickerInput {
    display: flex;
    align-items: center;
    border: none;
    > .DateInput {
      width: auto;
      border: 1px solid $main-color;
      border-radius: 14px;
      max-width: 110px;
      > .DateInput_input {
        padding: 2px 10px;
        font-size: 14px;
        border-radius: 14px;
        &.DateInput_input__focused {
          border-bottom: none;
        }
      }
    }
    > .DateRangePickerInput_arrow {
      width: 15px;
      height: 1px;
      background-color: #649AF0;
      > .DateRangePickerInput_arrow_svg {
        display: none;
      }
    }
  }
}

// Custom search bar
.custom-search{
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ccc;
}

.custom-search-outline{
  margin-top: 10px;
  margin-left: 10px;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

// Status
.status-container {
  display: inline-block;
  text-align: center;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 13px;
  color: #3e3e3e;
  background-color: #f5f5f5;
}
.status1-container {
  background-color: #CFCFCF;
  color: #000;
}
.status2-container {
  background-color: #E77900;
  color: #ffffff;
}
.status3-container {
  background-color: #BF4A81;
  color: #ffffff;
}
.status4-container {
  background-color: #45537D;
  color: #ffffff;
}
.status5-container {
  background-color: #007E3B;
  color: #ffffff;
}
.status6-container {
  background-color: #008897;
  color: #ffffff;
}
.status7-container {
  background-color: #1D83C4;
  color: #ffffff;
}
.status8-container {
  background-color: #C35256;
  color: #ffffff;
}
.status9-container {
  background-color: #00B597;
  color: #ffffff;
}
.status10-container {
  background-color: #FFBFBD;
}
.status11-container {
  background-color: #A46859;
  color: #ffffff;
}
.status12-container {
  background-color: #BEA6A0;
}

// Cards

.daycard-container {
  padding: 10px 0;
  .daycard-dragger {
    display: flex;
    align-items: center;
  }
}

// Day cards
.daycard-block {
  flex: 1;
  .daycard {
    &.daycard-selected {
      border-color: $second-color;
    }
    .daycard-header {

    }
    .daycard-content {

    }
    .daycard-actions {

    }
    .daycard-collapse {
      .daycard-collapse-inner {
        padding: 16px;
        background-color: #ececec;
      }
    }
  }
}

.miniSiteMain {
  .daycard {
    background-color: #eff5ff;
  }
}

// Timeline
.dayBenefitTimeline {
  padding-right: 0;
  padding-left: 0;
  .timelineItem {
    .timelineItemContent {
      .timelineCardDeleted {
        background-color: #bdbdbd;
      }
      .timelineItemHeader {
        padding-bottom: 0;
      }
      .timelineItemActions {
        padding-top: 0;
      }
    }
    .MuiTimelineOppositeContent-root {
      padding-left: 0;
    }
    .MuiTimelineContent-root {
      padding-right: 0;
    }
    .timelineConnector {
      background-color: $main-color-light;
    }
    &.MuiTimelineItem-missingOppositeContent {
      &:before {
        flex: none;
        padding: 0;
      }
    }
  }
}

.timelineInsideTitle {
  margin: 0;
  color: #ffffff;
  background-color: #649AF0;
  padding: 2px 10px;
  font-size: 0.9rem;
}
.timelineParticipantsContainer {
  padding: 0 16px;
  background-color: $main-color-lighter;
}

.icon-to-expand {
  margin-left: auto !important;
  transition: transform 500ms !important;
}
.icon-expanded {
  transform: rotate(180deg);
}

.add-a-day-line-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .add-a-day-line {
    flex-grow: 1;
    height: 1px;
    margin: 3px 8px 0;
    border-bottom: 1px dashed #ccc;
  }
  .add-a-day-line-text {
    flex-grow: 0;
  }
}

// Tooltip
.tooltipHelpIcon {
  color: $grey-color;
  &:hover {
    color: $second-color;
  }
}

.ckeditor-card-container-price {
  padding: 10px 30px;
}

// Timeline common
.timelineCardMenuDelete {
  background-color: #f44336 !important;
  color: #ffffff !important;
}

// Map popup
.mapPopup {
  &.leaflet-popup {
    margin-bottom: 50px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 8px;
    .leaflet-popup-content {
      p {
        margin: 0 0 5px 0;
      }
    }
  }
}

// Benefit properties list
.benefitPropertiesList {
  display: flex;
  flex-wrap: wrap;
  .benefitPropertiesListItem {
    flex-basis: 50%;
    padding: 2px 0;
  }
}

// Search chips
.searchChipsContainer {
  margin-bottom: 10px;
  .MuiChip-root, > .MuiAvatar-root, > .customChip {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.customChip {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px;
}

// Search filters
.searchFiltersContainer {
  display: flex;
  flex-direction: column;
  .searchFilterBenefitTypes,
  .searchFilterEstablishment,
  .searchFilterLocationContainer {
    margin-top: 10px;
  }
  .searchFilterParticipants {
    margin-top: 16px;
  }
  .searchFilterLocationContainer {

  }
}

// Ckeditor
.ckeditor-card-container {
  > .ck-content {
    border: 1px solid #bdbdbd !important;
    background-color: #fff;
  }
}

// Description display
.descriptionContainer {
  border: 1px dashed $main-color-lighter;
  padding: 0 10px;
}

.descriptionV2Container {
  border: 1px solid #ccc;
  border-radius: $basic-border-radius;
  background-color: #ffffff;
  padding: 5px 10px;
}

// Announcements
.announcementDisplayBlock {
  border: 2px outset $main-color;
  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 25px;
    > h2 {
      border-bottom: 1px solid $main-color;
      padding-bottom: 5px;
    }
    > div {
      margin-bottom: 0;
      margin-top: 5px;
      font-style: italic;
    }
  }
}

@media (max-width: 650px) {
  .formTwoCol {
    grid-template-columns: 1fr;
  }
}