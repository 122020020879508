@import "variables";

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

@media print {
  .no-print {
    display: none;
  }
  .pageBreakAfter {
    break-after: page;
  }
  .no-background {
    background-color: transparent !important;
    background: none;
  }
  .noPageBreakInside {
    break-inside: avoid-page;
  }
}