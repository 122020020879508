$main-color: #649AF0;
$main-color-hover: #4682e2;
$main-color-light: #aacbff;
$main-color-lighter: #dbe9ff;
$second-color: #8E77D1;
$second-color-hover: #7051c7;
$second-color-light: #e5dcff;
$error-color: #da0000;
$success-color: #4caf50;
$grey-color: #7b7b7b;
$light-grey-color: #efefef;

$basic-border-radius: 4px;